<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <!-- Table Container Card -->

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_trendAnalyze") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_blackList") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-on:keydown.enter="get_records_by_phone"
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              small
              style="max-height: 100vh"
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>

              <template #cell(is_inbound)="data">
                <feather-icon
                  :icon="data.item.is_inbound ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_outbound)="data">
                <feather-icon
                  :icon="data.item.is_outbound ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="getSnapshots(data.item)">
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50">{{ lang("t_show") }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="totalUsers"
                first-number
                last-number
                @input="get_records"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      dialog-class="dClass75"
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <!-- Name -->
      <b-form-group :label="lang('t_displayName')" label-for="name">
        <b-form-input
          :placeholder="lang('t_displayName')"
          id="name"
          v-model="currentData.Name"
          trim
        />
      </b-form-group>

      <!-- Comment -->
      <b-form-group :label="lang('t_description')" label-for="description">
        <b-form-input
          v-model="currentData.Comment"
          id="description"
          :placeholder="lang('t_description')"
        >
        </b-form-input>
      </b-form-group>

      <b-row>
        <b-col>
          <b-row class="align-items-center">
            <b-col>
              <span>Son X Gün/Saat</span>
            </b-col>
            <b-col>
              <b-form-input id="LastDate" v-model="currentData.LastDate" trim />
            </b-col>
            <b-col>
              <v-select
                v-model="currentData.DateOptionLastDate"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.id"
                :options="date_options"
                :closeOnSelect="true"
                :clearable="false"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="align-items-center">
            <b-col>
              <span>Son X Çağrı</span>
            </b-col>
            <b-col>
              <b-form-input id="LastCall" v-model="currentData.LastCall" trim />
            </b-col>
            <b-col>
              <v-select
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="'Çağrı'"
                :closeOnSelect="true"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="align-items-center">
            <b-col>
              <span>Snapshot Periyodu</span>
            </b-col>
            <b-col>
              <b-form-input
                id="SnapshotPeriod"
                v-model="currentData.SnapshotPeriod"
                trim
              />
            </b-col>
            <b-col>
              <v-select
                v-model="currentData.SnapshotPeriod"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.id"
                :options="date_options"
                :closeOnSelect="true"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <hr />
      <h4>Filtreler</h4>
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_projects')">
            <v-select
              v-model="currentData.Projects"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="projects"
              :closeOnSelect="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_queues')">
            <v-select
              v-model="currentData.Queue"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.display_name"
              :options="queues"
              :closeOnSelect="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_users')">
            <v-select
              v-model="currentData.Users"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="username"
              :reduce="(item) => item.identifier.replace('@', '_')"
              :options="users"
              :closeOnSelect="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_direction')">
            <v-select
              placeholder="Yön"
              v-model="currentData.CallDirections"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.id"
              :options="call_direction_options"
              multiple
              :closeOnSelect="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_search')">
            <b-form-input
              id="SearchText"
              v-model="currentData.SearchText"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_speaker')">
            <v-select
              v-model="currentData.Speaker"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.id"
              :options="speakers"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_keywordCategories')">
            <v-select
              placeholder="Anahtar Kelime Kategorisi"
              v-model="currentData.KeyWordSpottings"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="display_name"
              :reduce="(item) => item.callexper_id"
              :options="keyword_categories"
              multiple
              :closeOnSelect="false"
            >
              <template #list-header>
                <div
                  class="my-50 mx-50 d-flex justify-content-center align-items-center"
                >
                  <b-form-radio
                    class="mr-1"
                    v-model="currentData.KeyWordSpottingsLogicRule"
                    :value="0"
                    @change="updateKeyWordSpottingsLogicRule(0)"
                  >
                    Ve
                  </b-form-radio>
                  <b-form-radio
                    class="ml-1"
                    v-model="currentData.KeyWordSpottingsLogicRule"
                    :value="1"
                    @change="updateKeyWordSpottingsLogicRule(1)"
                  >
                    Veya
                  </b-form-radio>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <div class="d-flex align-items-center">
            <b-form-group :label="lang('Süre Başlangıç')">
              <b-form-input
                v-model="currentData.DurationMin"
                class="mr-50"
                type="number"
                placeholder="Süre Başlangıç"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="lang('Süre Bitiş')">
              <b-form-input
                v-model="currentData.DurationMax"
                class="ml-50"
                type="number"
                placeholder="Süre Bitiş"
                trim
              ></b-form-input>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_finishCode')">
            <v-select
              v-model="currentData.SipKeyValue"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="finish_code"
              :reduce="(item) => item.finish_code"
              :options="finish_codes"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_wordDistance')">
            <b-form-input
              v-model="currentData.WordDistance"
              type="number"
              :placeholder="lang('t_wordDistance')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_segmentDistance')">
            <b-form-input
              v-model="currentData.SegmentDistance"
              type="number"
              :placeholder="lang('t_segmentDistance')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="lang('t_maxWordGroupCount')">
            <b-form-input
              v-model="currentData.MaxWordGroupCount"
              type="number"
              :placeholder="lang('t_maxWordGroupCount')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <b-form-checkbox
        inline
        v-model="currentData.IsActive"
        class="custom-control-primary"
      >
        <span class="vs-checkbox">
          <span class="vs-checkbox--check">
            {{ lang("t_active") }}
          </span>
        </span>
      </b-form-checkbox> -->
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea,
  BSpinner,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BFormFile,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BFormRadio,
    BFormFile,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      modal_excel: false,
      currentData: {
        "Name": "",
        "Comment": "",
        "LastDate": 0,
        "LastCall": 0,
        "SnapshotPeriod": 1,
        "SearchText": "",
        "CustomData": "",
        "DurationMin": 0,
        "DurationMax": 0,
        "SipKey": "",
        "WordDistance": 0,
        "SegmentDistance": 0,
        "MaxWordGroupCount": 0,
        "IsActive": true,
        "CustomWords": false,
        "DateOptionLastDate": 1,
        "DateOptionSnapShot": 1,
        "Organizations": [
          "6590371572a81a09685664e5"
        ],
        "Projects": [],
        "Users": [],
        "KeyWordSpottings": [],
        "KeyWordSpottingsLogicRule": 1,
        "Queue": [],
        "SipKeyValue": [],
        "CallDirections": [],
        "Speaker": null
      },

      date_options: [
        { id: 0, internal_name: 'hour', display_name: 'Saat' },
        { id: 1, internal_name: 'day', display_name: 'Gün' },

      ],
      speakers: [
        { id: 0, internal_name: 'agent', display_name: globalThis._lang('t_agent') },
        { id: 1, internal_name: 'customer', display_name: globalThis._lang('t_customer') },
        { id: 2, internal_name: 'one_of_both', display_name: globalThis._lang('t_oneOfBoth') },
      ],
      call_direction_options: [
        { id: 0, internal_name: 'inbound', display_name: 'Gelen Çağrı' },
        { id: 1, internal_name: 'outbound', display_name: 'Gİden Çağrı' },

      ],
      users: [],
      queues: [],
      projects: [],
      finish_codes: [],
      keyword_categories: [],
      searchQuery: "",
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      records: [],
      tableColumns: [
        { key: "_id", label: "ID", sortable: true },
        {
          key: "Name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },

        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }

        },
      ],
    };
  },
  mounted: function () {
    this.finish_codes = globalThis.finish_codes;

    this.GetQueues();
    this.get_records();
    this.GetUsers();
    this.GetProjects();
    this.GetKeyWordCategories();
  },
  methods: {


    updateKeyWordSpottingsLogicRule(value) {
      console.log("updateKeyWordSpottingsLogicRule", value)
      this.currentData.KeyWordSpottingsLogicRule = value;
      this.$forceUpdate();
      console.log("updateKeyWordSpottingsLogicRule", this.currentData.KeyWordSpottingsLogicRule);

    },
    get_records: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(
          `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetTrendAnalyze`
        )
      ).data;
      console.log("resp", response);
      this.records = response;
      this.totalUsers = response.length;
      this.is_progress = false;
    },

    check_data() {
      if (this.currentData.Name == "") {
        return true;
      }
      return false;
    },

    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.is_progress = true;

      var response;
      response = (
        await this.$http_in.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/SaveTrendAnalyze`, {
          data: this.currentData,
          isEdit: this.isEdit
        })
      ).data;

      this.is_progress = false;

      if (response && response.ResultCode == 0) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;

      var response = (
        await this.$http_in.delete(
          `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/DeleteTrendAnalyze/${this.currentData._id}`
        )
      ).data;
      this.is_progress = false;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        "Name": "",
        "Comment": "",
        "LastDate": 0,
        "LastCall": 0,
        "SnapshotPeriod": 1,
        "SearchText": "",
        "CustomData": "",
        "DurationMin": 0,
        "DurationMax": 0,
        "SipKey": "",
        "WordDistance": 0,
        "SegmentDistance": 0,
        "MaxWordGroupCount": 0,
        "IsActive": true,
        "CustomWords": false,
        "DateOptionLastDate": 1,
        "DateOptionSnapShot": 1,
        "Organizations": [],
        "Projects": [],
        "Users": [],
        "KeyWordSpottings": [],
        "KeyWordSpottingsLogicRule": 0,
        "Queue": [],
        "SipKeyValue": [],
        "CallDirections": [],
        "Speaker": null,
      };
    },
    async updateSelected(item) {
      var response = (
        await this.$http_in.get(
          `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetTrendAnalyze/${item._id}`
        )
      ).data;
      this.currentData = { _id: item._id, ...response };

      console.log("item", response);
      this.isEdit = true;
      //this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    async getSnapshots(item) {
      var response = (
        await this.$http_in.get(
          `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetSnapshot/${item._id}`
        )
      ).data;

      this.snapshot = response;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
    GetUsers: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;
      this.users = response;

    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
        }
      }

      // this.filter_values.Queues = this.queues.map(e => e.display_name);
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    GetKeyWordCategories: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(
          `/spv/v1/KeywordCategory`
        )
      ).data;

      this.keyword_categories = response;
      this.is_progress = false;
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
